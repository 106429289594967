
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { Button, Container, Flex, Heading } from '@chakra-ui/react';
import NextLink from 'next/link';
import useTranslation from 'next-translate/useTranslation';
import { TowTruckIcon } from 'components/elements/Icons/TowTruckIcon';
import { Layout } from 'components/layouts/Layout';
export const NotFoundPage = (): JSX.Element => {
    const { t } = useTranslation('common');
    return (<Layout>
      <Container alignSelf="center" justifySelf="center" m={{ base: 0, md: 10 }} width="desktop-content" height="full" p={0} background="primary.white">
        <Flex p={13} direction="column" alignItems="center" justifyContent="center" textAlign="center">
          <Flex boxSize="11.5rem" fontSize="sm" borderRadius="50%" bgColor="secondary.beige.100" alignItems="center" justifyContent="center">
            <TowTruckIcon boxSize="5.75rem" color="primary.red"/>
          </Flex>
          <Heading as="h1" mb={12} mt={8} fontSize={{ base: 'lg', sm: 'lg' }}>
            {t('not-found-text')}
          </Heading>
          <Button as={NextLink} href="/">
            {t('continue-shopping-text')}
          </Button>
        </Flex>
      </Container>
    </Layout>);
};
export default NotFoundPage;

    async function __Next_Translate__getStaticProps__19504e23bd6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/404.page',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__19504e23bd6__ as getStaticProps }
  